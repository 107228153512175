import Header from '../components/Header'
import ServicesModal from '../components/ServicesModal';
import AppSelectionList from '../components/AppSelectionList';
import React, { useState } from "react";
import { IonContent, IonPage } from '@ionic/react';
import { createBrowserHistory } from 'history';


const defaultHistory = createBrowserHistory()

//create context for services after fetch

const Home: React.FC = () => {
  const [modalEngage, setModalEngage] = useState(false);

  return (
    <IonPage>
      <Header modalEngage={modalEngage}
        setModalEngage={setModalEngage}        
      />
      <IonContent>
        <ServicesModal showModal={modalEngage} setModalEngage={setModalEngage} />
        <AppSelectionList />
      </IonContent>
    </IonPage>
  );
};

export default Home;
