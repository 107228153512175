import { Redirect, Route } from 'react-router-dom';
import { IonApp, IonRouterOutlet } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import InteractionsList from './pages/InteractionsList';

const InteractionsApp: React.FC = () => (      
   <InteractionsList />
)

export default InteractionsApp;


  // <IonApp>
  //   <IonReactRouter>
  //     <IonRouterOutlet>
  //       <Route exact path="/interactions">
  //<InteractionsList />
  /* </Route>
  <Route exact path="/">
    <Redirect to="/interactions" />
  </Route>
</IonRouterOutlet>
</IonReactRouter>
</IonApp>
); */