import React from "react";

import InteractionsApp from '../Apps/InteractionsApp/src/InteractionsApp';

interface MicroInstance {
    name: string,    
    history: History
}

const Micro: React.FC<MicroInstance> = ({name}) => {

  let microApp;
  if(name === "Interactions") {
    microApp = <InteractionsApp />
  }

  return(
    <div>
      {microApp}
    </div>
  )  
  
};

export default Micro