import { 
  IonButton,
  IonButtons,
  IonHeader,
  IonIcon,   
  IonRouterLink,  
  IonTitle,
  IonToolbar
 } from '@ionic/react';
import { 
  menu as MenuIcon,
  logOut as LogOutIcon,  
 } from 'ionicons/icons';

import { useOktaAuth } from "@okta/okta-react";
import { useEffect, useState } from 'react';
import axios from 'axios';
import './Header.css'

interface ContainerHeader {
  modalEngage: boolean,
  setModalEngage: Function,
}

const {
  REACT_APP_GET_ACCESS_POLICY: getAccessPolicyAPI
} = process.env    

const Header: React.FC<ContainerHeader> = ({modalEngage, setModalEngage }) => {

  const { oktaAuth, authState } = useOktaAuth();  
  const [userName, setUserName] = useState(''); 


  useEffect(() => {
    if(authState) {
      oktaAuth.getUser()
      .then(res => {
        setUserName(res.name)
        return res.email
      })
      .then((email) => {    
        let accessToken = JSON.parse(localStorage['okta-token-storage']).accessToken.accessToken;    
        axios({
          method: 'post',
          url: getAccessPolicyAPI,
          data: {
            email: email,
            accessToken: accessToken
          }
        })
        .then((res) => {
          if(res.data.body === 'Token not valid') {
            logout();
          } else {
            sessionStorage['user-props'] = res.data.body
          }          
        })
        .catch((err) => {
          if(err) {
            console.log('Error Fetching Policy')
          }
        })
      })
    }
    if(!authState.isAuthenticated) {      
      logout()
    }
  }, [oktaAuth, authState])    

  const login = async () => { await oktaAuth.signInWithRedirect(); }
  const logout = async () => { 
    await oktaAuth.signOut({revokeAccessToken: true})    
  }

  let logButton;
  if(authState) {
    logButton = authState.isAuthenticated
      ? <IonButton onClick={ logout }><IonIcon slot="icon-only" icon={LogOutIcon}/></IonButton>
      : <IonButton onClick={ logout }><IonIcon slot="icon-only" icon={LogOutIcon}/></IonButton>;       
  }

  return (
    <IonHeader className="container-header">
      <IonToolbar >
        <IonButtons slot="start">
          <IonButton onClick={() => {setModalEngage(!modalEngage)}}>
            <IonIcon slot="icon-only" icon={MenuIcon} />
          </IonButton>
        </IonButtons>
        <IonButtons slot="end">          
          {userName}
          {logButton}
        </IonButtons>        
        <IonTitle >
          <IonRouterLink color="dark" routerLink='/home'>
            <img style={{width: '25%'}}src="https://patientsync-icons.s3.amazonaws.com/R3_PatientSync_Logo_Clr_Reversed_Horizontal.png"/> 
          </IonRouterLink>
        </IonTitle>
      </IonToolbar>
    </IonHeader>
  );
};

export default Header;


//