import { IonApp } from '@ionic/react';

import { LoginCallback, SecureRoute, Security } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';

import { useHistory } from 'react-router-dom';
import { Route, Switch, Redirect } from 'react-router-dom';

import Home from './pages/Home';
import NotFoundPage from './pages/NotFoundPage';
import ContainerService from './pages/ContainerService';

const {
  REACT_APP_OKTA_ISSUER: oktaIssuer,
  REACT_APP_OKTA_PUBLIC: oktaPublic,
} = process.env

const oktaAuth = new OktaAuth({
  issuer: oktaIssuer, 
  clientId: oktaPublic,
  redirectUri: window.location.origin + '/callback',
  scopes: ['openid', 'email', 'profile', 'offline_access']  
});

const App: React.FC = () => {  
  const history = useHistory()  
  const restoreOriginalUri = async (_oktaAuth, originalUri) => {                 
    history.replace(toRelativeUrl(originalUri, window.location.origin)); 
  };

  return (
    <IonApp>       
      <Security oktaAuth={oktaAuth}
        restoreOriginalUri={restoreOriginalUri}
      >
        <Switch>
          <SecureRoute exact path='/home' component={Home} />
          <SecureRoute exact path='/my/:service' component={ContainerService} />
          <SecureRoute exact path='/my' /> 
          <Redirect exact path="/" to="/home"/> 
          <Redirect exact path="/my" to="/home"/>                          
          <Route path='/callback' component={LoginCallback} />
          <Route component={NotFoundPage}/>        
        </Switch>
      </Security>
    </IonApp>
  )
};

export default App;
