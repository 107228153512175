import { useState, useEffect } from 'react';
import {
  IonContent,
  IonPage,
  IonHeader,
  IonToolbar,
  IonItem,
  IonSelect,
  IonSelectOption,
  IonLabel,
  IonText,
  IonIcon,
  IonButtons,
  IonButton,
  IonDatetime
} from '@ionic/react';
import axios from 'axios';
import { CSVLink } from 'react-csv';
import { 
  download as DownloadIcon,  
  checkbox as CheckboxIcon   
} from 'ionicons/icons';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import Interaction from '../components/Interaction';

import './InteractionsList.css';

dayjs.extend(utc);
dayjs.extend(timezone);

const now = dayjs()

const {
  REACT_APP_INTERACTIONS_UPDATE_ITEM: updateInteractionItemAPI,
REACT_APP_INTERACTIONS_GET_ITEMS: getInteractionsAPI
} = process.env

const InteractionsList: React.FC = () => {

  const [interactions, setInteractions] = useState([]);
  const [timeZone, setTimeZone] = useState("America/Chicago");
  const [tenant, setTenant] = useState('');
  const [segmentList, setSegmentList] = useState([]);
  const [selectedSegment, setSelectedSegment] = useState({segment_id: '', segment_name: ''});
  const [csvSelectorObj, setCsvSelectorObj] = useState({});
  const [csvSelectorArr, setCsvSelectorArr] = useState([]);
  const [startDate, setStartDate] = useState(now.startOf('day').subtract(1, 'day').tz("America/Chicago").toISOString());
  const [endDate, setEndDate] = useState(now.endOf('day').tz('America/Chicago').toISOString());
  const [csvHeaders, setCsvHeaders] = useState([
    {label: "Interaction Id", key: "interaction_id"},
    {label: "Escalated", key: "escalated"},
    {label: "Callback", key: "body.callback"},
    {label: "Caller", key: "body.caller"},
    {label: "Patient Name", key: "body.patient_name"},
    {label: "DOB", key: "body.dob"},
    {label: "Location", key: "body.location"},
    {label: "Primary Provider", key: "body.primary_provider"},
    {label: "Details", key: "body.details"},
    {label: "Date & Time", key: "modifiedOutgoing_dateTime"},
    {label: "Modifications", key: "modificationsString"} 
  ])

  useEffect(() => {
    //fetch tenant and segments
    let sessionInfo = JSON.parse(sessionStorage['user-props'])
    let firstTenant = sessionInfo.tenantRelationships[0].tenant_id;
    let firstSegment = sessionInfo.tenantRelationships[0].segmentRelationships[0].segment_id
    setTenant(firstTenant)
    setSegmentList(sessionInfo.tenantRelationships[0].segmentRelationships)
    setSelectedSegment(sessionInfo.tenantRelationships[0].segmentRelationships[0])
    // set states        
    fetchInteractions(firstSegment)
  }, [])

  useEffect(() => {
    fetchInteractions(selectedSegment.segment_id)
  }, [startDate, endDate])

  const changeStatus = async (id, status) => {
    axios({
      method: 'post',
      url: updateInteractionItemAPI, 
      data: {
        "interaction_id": id,
        "updateItems": {
          "interaction_status": status
        },
        "accessToken": JSON.parse(localStorage['okta-token-storage']).accessToken.accessToken
      }
    })
      .then(() => {
        let newArr = [...interactions];
        let interaction = newArr.find(id => id)
        interaction.interaction_status = status;
        setInteractions(newArr);
      })
  }

  const fetchInteractions = async (segment_id) => {
    axios({
      method: 'post',
      url: getInteractionsAPI,
      headers: {
        "Content-Type": 'application/json',
      },
      data: {        
        "segment_id": segment_id,
        "startDate": startDate,
        "endDate" : endDate,
        "accessToken": JSON.parse(localStorage['okta-token-storage']).accessToken.accessToken
      }
    })
      .then((res) => {
        let interactionsRes = JSON.parse(res.data.body)
        interactionsRes.map(int => {
          int.checked = false;
        });
        setInteractions(interactionsRes);
      })      
      .catch((err) => {
        if (err) console.log('error fetching data : ', err)
      })
  }

  const segmentChange = (segment_id, segment) => {
    fetchInteractions(segment_id)
    .then(() => {
      setSelectedSegment(segment)      
      setCsvSelectorObj({});
      setCsvSelectorArr([]);
    })
    .catch((err) => {
      if(err) {
        console.log('Error changing segments')
      }
    })
  }

  const convertToModifiedString = (modifications) => {
    let modificationsStr = '';
    for(let i = 0; i < modifications.length; i++) {
      modificationsStr += `Modified Date : ${dayjs(modifications[i].modify_dateTime).tz(timeZone).format('MMM/DD/YYYY')},  
      Modified Time : ${dayjs(modifications[i].modify_dateTime).tz(timeZone).format('h:mm A')}, 
      TimeZone : ${timeZone} 
      User : ${modifications[i].modify_user}, 
      Modified Details : ${modifications[i].modify_details} 
      --
      `;
    }
    return modificationsStr;
  }

  const checkboxCsvList = (interaction, date, time, timeZone) => {
    let tempInteraction = {...interaction}         
    if(csvSelectorObj[tempInteraction.interaction_id] >= 0) {      
      let index = csvSelectorObj[tempInteraction.interaction_id];      
      csvSelectorArr[index] = csvSelectorArr[csvSelectorArr.length - 1];
      csvSelectorObj[(csvSelectorArr[index].interaction_id)] = index
      csvSelectorArr.pop();
      delete csvSelectorObj[tempInteraction.interaction_id];
      interaction.checked = false;
    } else {      
      tempInteraction.modifiedOutgoing_dateTime = `${date} ${time} ${timeZone}`;
      if(tempInteraction.escalated === true) {
        tempInteraction.escalated = 'Yes'
      } else {
        tempInteraction.escalated = 'No'
      }
      interaction.checked = true;
      tempInteraction.modificationsString = convertToModifiedString(tempInteraction.modifications);
      csvSelectorObj[tempInteraction.interaction_id] = csvSelectorArr.push(tempInteraction) - 1;
    }
    setCsvSelectorObj({...csvSelectorObj});   
    setCsvSelectorArr([...csvSelectorArr]);
  }

  const selectAll = () => {
    let tempInteractions = [...interactions]
    if(csvSelectorArr.length && (csvSelectorArr.length === interactions.length)) {
      tempInteractions.map(interaction => {
        interaction.checked = false;
      })
    } else {
      tempInteractions.map(interaction => {
        //checkboxCsvList(interaction, dayjs(interaction.outgoing_dateTime).tz(timeZone).format('MMM/DD/YYYY'), dayjs(interaction.outgoing_dateTime).tz(timeZone).format('h:mm A'), timeZone)
        interaction.checked = true;
      });
    }
    setInteractions(tempInteractions)
  }



  let downLoad = csvSelectorArr.length 
    ?
    <IonItem slot="end" lines="none">              
      <CSVLink data={csvSelectorArr} headers={csvHeaders} filename={'Interactions'}>
        <IonButtons >
          <IonButton >
            <IonIcon slot="icon-only" icon={DownloadIcon}/> 
          </IonButton>
        </IonButtons>
      </CSVLink>
    </IonItem>
    :
    null
  

  return (
    <IonPage className="interactions-page">
      <IonHeader>
        <IonToolbar>
          <IonText slot="start" className="ion-padding-start">
            Interactions Log
          </IonText>
          <IonItem slot="start" lines="none">
            <IonButtons color="primary">
              <IonButton color="primary" onClick={() => {selectAll()}}>
                Select All
                {/* <IonIcon slot="icon-only" icon={CheckboxIcon} /> */}
              </IonButton>
            </IonButtons>
          </IonItem>
          {downLoad}
          <IonItem slot="end" lines="none">
            <IonLabel position="stacked">Start Date:</IonLabel>
            <IonDatetime displayFormat="MMM/DD/YYYY"
              value={startDate}
              onIonChange={(e) => {
                let changeTime = dayjs(e.detail.value).tz(timeZone).toISOString()
                return setStartDate(changeTime)
              }}
            />
          </IonItem>
          <IonItem slot="end" lines="none">
            <IonLabel position="stacked">End Date:</IonLabel>
            <IonDatetime displayFormat="MMM/DD/YYYY"
              value={endDate}
              onIonChange={(e) => {
                let changeTime = dayjs(e.detail.value).tz(timeZone).toISOString();
                return setEndDate(changeTime)
              }}
            />
          </IonItem>
          <IonItem slot="end" lines="none">
            <IonLabel position="stacked">
              Segment :
            </IonLabel>
            <IonSelect value={selectedSegment} onIonChange={event => {
              segmentChange(event.detail.value.segment_id, event.detail.value)
            }}
            >
              {segmentList.map(segment => {
                return (
                  <IonSelectOption key={segment.segment_id} value={segment}>{segment.segment_name}</IonSelectOption>
                )
              })}
            </IonSelect>
          </IonItem >
          <IonItem slot="end" lines="none">
            <IonLabel position="stacked">
              TimeZone :
            </IonLabel>
            <IonSelect onIonChange={event => setTimeZone(event.detail.value)} value={timeZone}>
              <IonSelectOption value="America/New_York">EST</IonSelectOption>
              <IonSelectOption value="America/Chicago">CST</IonSelectOption>
              <IonSelectOption value="America/Denver">MST</IonSelectOption>
              <IonSelectOption value="America/Phoenix">AZ</IonSelectOption>
              <IonSelectOption value="America/Los_Angeles">PST</IonSelectOption>
            </IonSelect>
          </IonItem>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {interactions.map((interaction) => {
          return (
            <Interaction
              key={interaction.interaction_id}
              interaction={interaction}
              changeStatus={changeStatus}
              timeZone={timeZone}
              checkboxCsvList={checkboxCsvList}
            />
          )
        })}
      </IonContent>
    </IonPage>
  );

};

export default InteractionsList;


//"ion-align-self-center ion-align-items-stretch ion-justify-content-evenly"

{/* <IonContent >
<IonGrid>
  <IonCol>
    <IonRow>
      <IonInfiniteScroll >
        <IonInfiniteScrollContent>
          <IonList>
            {interactions.map((interaction) => {
              return (
                <Interaction
                  key={interaction.interaction_id}
                  interaction={interaction}
                />
              )
            })}
          </IonList>
        </IonInfiniteScrollContent>
      </IonInfiniteScroll>
    </IonRow>
  </IonCol>
</IonGrid>
</IonContent> */}