import { IonGrid, IonRow, IonCol} from "@ionic/react"
import React, { useEffect, useState } from "react";
import InteractionsApp from "../InteractionsApp";
import dayjs from 'dayjs';

import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

interface InteractionGridProps { 
  interaction: {
    interaction_id: string,
    escalated: boolean,
    interaction_status: string, 
    body: {
      primary_provider: string,
      details: string
      caller: string,
      dob: string,
      callback: string,
      html: string,
    },
    incoming_id: string,
    outgoing_dateTime: string
    modifications: {modify_dateTime: string, modify_details: string, modify_user: string}[]
  },
  timeZone: string   
}



const InteractionGrid: React.FC<InteractionGridProps> = React.memo(({interaction, timeZone}) => {  
  // const [microHTML, setMicroHTML] = useState('')
  
  // useEffect(() => {
  //   if(interaction.body && interaction.body.html) {
  //     setMicroHTML(parseHTML(interaction.body.html))
  //   }
  // }) 

  return (
    <div>
      <div dangerouslySetInnerHTML={{__html: interaction.body.html}}></div>
      <div><b>Modifications :</b></div>
      {interaction.modifications.map((mod) => {
        return (
          <ul >
            <li>{`Date : ${dayjs(mod.modify_dateTime).tz(timeZone).format('MMM/DD/YYYY')}, Time : ${dayjs(mod.modify_dateTime).tz(timeZone).format('h:mm A')}, TimeZone : ${timeZone}`}</li>
            <li>{`User : ${mod.modify_user}`}</li>
            <li>{`Details : ${mod.modify_details}`}</li>
          </ul>
        )
      })}
    </div>
  )
})

export default InteractionGrid

{/* <IonGrid className="ion-margin-horizontal"> 
<IonRow className="ion-align-items-center ion-margin-horizontal ion-justify-content-evenly">
    <IonCol size="12" size-lg className="ion-margin-horizontal">              
      <h1>Caller</h1>               
      <h2>{interaction.body.caller}</h2>
    </IonCol>
    <IonCol size="12" size-lg className="ion-margin-horizontal">
      <h1>CallBack Number</h1>
      <h2>{interaction.body.callback}</h2>
    </IonCol>          
    <IonCol size="12" size-lg className="ion-margin-horizontal">
      <h1>DOB</h1>
      <h2>{interaction.body.dob.split(' ')[0]}</h2>
    </IonCol>
    <IonCol size="12" size-lg className="ion-margin-horizontal">              
      <h1>Primary Provider</h1>               
      <h2>{interaction.body.primary_provider}</h2>
    </IonCol>
</IonRow>
<IonRow className="ion-align-items-center ion-margin-horizontal ion-justify-content-evenly">
    <IonCol size="12" size-lg className="ion-margin-horizontal">
      <h1>Details</h1>
      <h2>{interaction.body.details}</h2>
    </IonCol>          
</IonRow>
</IonGrid> */}