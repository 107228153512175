import React from 'react';
import { IonModal, IonInfiniteScroll, IonItem, IonList, IonLabel, IonImg, IonThumbnail, IonHeader, IonToolbar, IonButtons, IonButton, IonIcon, IonTitle, IonRouterLink, IonInfiniteScrollContent, IonContent } from '@ionic/react';
import { arrowBack as ArrowBackIcon } from 'ionicons/icons';
import { services } from '../services';

;

interface ContainerModal {
  showModal: boolean,
  setModalEngage: Function,
}

const ServicesModal: React.FC<ContainerModal> = ({ showModal, setModalEngage }) => {   

  //services should be passed via props

  return (
    <IonModal isOpen={showModal} onDidDismiss={() => setModalEngage(false)} swipeToClose={true}>
      <IonContent>
        <IonInfiniteScroll >
          <IonInfiniteScrollContent>                        
            <IonHeader>
              <IonToolbar>
                <IonButtons slot="start">
                  <IonButton onClick={() => setModalEngage(false)} >
                    <IonIcon slot="icon-only" icon={ArrowBackIcon} />
                  </IonButton>
                </IonButtons>
                <IonTitle ><IonRouterLink color="dark" routerLink='/home'>PatientSync</IonRouterLink></IonTitle>
              </IonToolbar>
            </IonHeader>
            <IonList>
              {services.map((service) =>
                <IonItem button key={service.id} routerLink={`/my/${service.service}`}>
                  <IonThumbnail>
                    <IonImg src={service.logo} key={service.id} />
                  </IonThumbnail>
                  <IonLabel className="ion-padding">{service.service}</IonLabel>
                </IonItem>
              )}
            </IonList>
          </IonInfiniteScrollContent>
        </IonInfiniteScroll>
      </IonContent>
    </IonModal>
  )
}

export default ServicesModal