import Header from '../components/Header'
import Micro from '../components/Micro'
import ServicesModal from '../components/ServicesModal';
import React, { useState, useEffect } from "react";
import { IonContent, IonPage } from '@ionic/react';
import { createBrowserHistory } from 'history';
import { useParams } from 'react-router';


const defaultHistory = createBrowserHistory()


const ContainerService: React.FC = () => {  
  const [modalEngage, setModalEngage] = useState(false);
  const [appList, setAppList] = useState([]);

  const { service } = useParams<{service?: string}>()

  return (
    <IonPage>
      <Header modalEngage={modalEngage} setModalEngage={setModalEngage}  />
      <IonContent>
        <ServicesModal showModal={modalEngage} setModalEngage={setModalEngage}/>
        <Micro name={service} history={window.history}/>
      </IonContent>
    </IonPage>
  );
};

export default ContainerService;